<template>
  <b-modal
    id="modal-assign"
    v-model="show"
    title="Phân nhóm"
    size="xl"
    centered
    hide-footer
    @hidden="resetModal"
  >
    <b-overlay :show="loading">
      <b-row>
        <b-col cols="4">
          <basic-input
            label="Nhóm"
            placeholder="Nhập mã hoặc tên nhóm"
            name="name"
            :value.sync="filter.name"
            :debounce="1000"
            @v-modal:update="searchDebounce($event, 'group')"
          ></basic-input>
        </b-col>
        <b-col cols="4">
          <basic-input
            label="Coach"
            placeholder="Nhập mã hoặc tên coach"
            name="coachName"
            :value.sync="filter.coachName"
            :debounce="1000"
            @v-modal:update="searchDebounce($event, 'coach')"
          ></basic-input>
        </b-col>
      </b-row>
      <template-table
        :column="column"
        :data="data"
        :paging="paging"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        :selectSingle="true"
        @sortBy="sortRequest"
        @select-single="select"
      >
        <template v-slot:body="{ item }">
          <td>{{ item.name }}</td>
          <td>
            <div class="d-flex align-items-center">
              <avatar
                size="40px"
                :text="item.healthCoachName"
                :src="item.healthCoachImage && item.healthCoachImage.url"
                :rounded="true"
              />
              <div class="d-flex flex-column ml-5">
                <p class="mb-0 module-list-page__body__updater-name">
                  {{ item.healthCoachName }}
                </p>
                <p class="mt-2 mb-0 module-list-page__body__updater-code">
                  Mã số: {{ item.healthCoachCode }}
                </p>
              </div>
            </div>
          </td>
          <td class="text-center">{{ item.patientPackageActive }}</td>
          <td>{{ item.createDatetime.split(' ')[0] }}</td>
          <td class="text-center">
            <a
              class="d-block a-link"
              href="javascript:void(0)"
              @click="openModalMapping(item)"
            >
              {{ item.mappingValue }}/11
            </a>
          </td>
        </template>
      </template-table>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <b-button class="btn mr-2" type="button" @click="resetModal">
          Hủy
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="submit"
          @click="handleSubmit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalAssign',
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
    isActiveAccount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      column: [
        {
          key: 'name',
          label: 'Nhóm',
          sortable: true,
        },
        {
          key: 'coach',
          label: 'Coach',
          sortable: false,
        },
        {
          key: 'number',
          label: 'Số lượng tham gia',
          sortable: false,
          class: 'text-center',
        },
        {
          key: 'date',
          label: 'Thời gian tạo',
          sortable: false,
        },
        {
          key: 'mapping',
          label: 'Tiêu chí phù hợp',
          sortable: false,
          class: 'text-center',
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
      data: [],
      selectCoach: null,
      loading: false,
      filter: {
        name: null,
        coachName: null,
      },
    };
  },
  computed: {
    searchParams() {
      return {
        page: this.paging.page,
        size: this.paging.pageSize,
        name: this.filter.name,
        coachName: this.filter.coachName,
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        accountPatientId: this.customer.accountId,
      };
    },
  },
  watch: {
    'paging.page'() {
      this.getCoachGroups();
    },
    'paging.pageSize'() {
      this.getCoachGroups();
    },
  },
  created() {
    this.getCoachGroups();
  },
  methods: {
    resetModal() {
      this.$emit('reset');
    },
    sortRequest(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.getCoachGroups();
    },
    select(params) {
      this.selectCoach = params;
    },
    async getCoachGroups() {
      try {
        this.$store.commit('context/setLoading', true);
        const { meta, data, error } = await this.$api.get(
          '/TrainingGroup/AssignUser/Search',
          {
            params: this.searchParams,
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
        this.data = data;
        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async handleSubmit() {
      try {
        this.loading = true;
        await this.$api.post('/TrainingGroup/AssignUser', {
          trainingGroupId: this.selectCoach,
          accountId: this.customer.accountId,
        });

        await this.$api.post('/PackageAccountTransaction/ChangeStatus', {
          id: this.isActiveAccount
            ? this.customer.id
            : this.customer.packageAccountTransactionId,
        });

        this.resetModal();
        this.$emit('reload-data');
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    searchDebounce(value, type) {
      if (type === 'group') {
        this.filter.name = value;
      } else {
        this.filter.coachName = value;
      }
      this.getCoachGroups();
    },
    openModalMapping(item) {
      this.$emit('open-modal-mapping', item);
    },
  },
};
</script>
<style lang="scss" scoped>
.a-link,
.a-link:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
</style>
